/** Subscribe to a websocket for a given device */
export const subscribeWebsocket = (deviceId: number, ws: WebSocket, type: 'data' | 'event') => {
  const payload = {
    action: `subscribe:${type}`,
    arguments: {
      nodeId: deviceId,
    },
  }
  if (ws.readyState === 1) ws.send(JSON.stringify(payload))
}

/** Group devices by device type */
export const groupByDeviceType = (devicesToUpdate: IDeviceType[]) => {
  const newDevices = devicesToUpdate.reduce((obj: Record<string, any>, device: IDeviceType) => {
    if (!obj.hasOwnProperty(device.nodeTypeName)) {
      obj[device.nodeTypeName] = []
    }
    obj[device.nodeTypeName].push(device)
    return obj
  }, {})
  return Object.values(newDevices)
}

/** Group devices by device location */
export const groupByDeviceLocation = (devicesToUpdate: IDeviceType[]) => {
  const newDevices = devicesToUpdate.reduce((obj: Record<string, any>, device: IDeviceType) => {
    if (!obj.hasOwnProperty(device.area || 'Undefined Location')) {
      obj[device.area || 'Undefined Location'] = []
    }
    obj[device.area || 'Undefined Location'].push(device)
    return obj
  }, {})
  return Object.values(newDevices)
}

/** Display devices that have alerts only */
export const displayAlertsOnly = (devicesToUpdate: IDeviceType[]) => {
  const newDevices = devicesToUpdate.filter(device => {
    // return if device has an alert
    return device.activeAlertsCount > 0
  })
  return newDevices
}

/** Parse an alarm name and return the user-friendly portion of the name */
export const parseAlarm = (alarm: IAlertHistory) => {
  // Ex: Level 2 - High Ambient Temperature - nbiot_leak (XX - XX - XX or XX - XX or XX - XX - XX - XX or Water Detected | nbiot_leak)
  const regexAlarm =
    alarm.name.match(/(^.+)-(.+)-(.+)/) || alarm.name.match(/(^.+)-(.+)/) || alarm.name.match(/(^.+)[|](.+)/)

  if (!regexAlarm) return alarm.name // If the alarm name does not match the regex pattern or any of the special cases, then return the alarm name as is

  let parsedAlarm
  const checkValidity = (str: string) => {
    if (
      str.toLowerCase().trim() === 'gateway' ||
      str.toLowerCase().trim() === 'motion temp' ||
      str.toLowerCase().trim() === 'motion' ||
      str.toLowerCase().trim() === 'motion2' ||
      str.toLowerCase().trim() === 'temp3' ||
      str.toLowerCase().trim() === 'temp' ||
      str.toLowerCase().trim() === 'water' ||
      str.toLowerCase().trim() === 'leak' ||
      str.toLowerCase().trim() === 'leak2' ||
      str.toLowerCase().trim() === 'leak3' ||
      str.toLowerCase().trim() === 'probe' ||
      str.toLowerCase().trim() === 'probe2' ||
      str.toLowerCase().trim() === 'bvs' ||
      str.toLowerCase().trim() === 'nbiot_leak' ||
      str.toLowerCase().trim() === 'device health' ||
      str.toLowerCase().trim() === '(urgent)' ||
      str.toLowerCase().trim().includes('motion v') ||
      str.toLowerCase().trim().includes('gateway v')
    )
      return false
    if (/level\s\d+/.test(str.toLowerCase().trim())) return false
    return true
  }

  // Test the first group to see if it's a valid status
  if (checkValidity(regexAlarm[1])) {
    parsedAlarm = regexAlarm[1].trim()
  }
  // Test the second group to see if it's a valid status
  if (checkValidity(regexAlarm[2])) {
    parsedAlarm = regexAlarm[2].trim()
  }
  // Test the optional third group to see if it's a valid status
  if (regexAlarm[3] && checkValidity(regexAlarm[3])) {
    parsedAlarm = regexAlarm[3].trim()
  }
  // Test the optional fourth group to see if it's a valid status
  if (regexAlarm[4] && checkValidity(regexAlarm[3])) {
    parsedAlarm = regexAlarm[4].trim()
  }

  return parsedAlarm
}

/** Get the number of alarms for a device or group of devices */
export const getAlarmCount = (devices: IDeviceType[]) => {
  let count = 0
  devices.forEach(device => {
    count += device.activeAlertsCount
  })
  return count
}

export const getGatewayStatus = (device: IDeviceType, alarms: IAlertHistory[]) => {
  let status: string = ''
  if (!device.isOffline) {
    let isOnBattery = false
    if (alarms) {
      alarms.map(alarm => {
        if (
          alarm.nodeId === device.id &&
          alarm.name.includes('Gateway Has Switched to Battery') &&
          alarm.status === 'active'
        ) {
          isOnBattery = true
        }
        return alarm
      })
    }
    if (isOnBattery) status = 'On Battery'
    else status = 'Online'
  } else if (device.isOffline) status = 'Offline'
  return status
}
